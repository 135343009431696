import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import AppLayout from './components/AppLayout';
import Login from './components/Login';
import Signup from './components/Signup';
import WhoWeAre from './components/WhoWeAre';
import LoadingPage from './components/LoadingPage';
import UseCases from './components/UseCases';
import Pricing from './components/Pricing';
import BusinessEvaluation from './components/BusinessEvaluation';
import Review from './components/Review';
import Billing from './components/Billing';
import ScrollToTop from './components/ScrollToTop';
import './App.css';

// About component
const About = () => (
  <div className="container mx-auto pt-16 p-4">
    <h1 className="text-2xl font-bold mb-4">About Us</h1>
    <p className="text-gray-600">This is the About page</p>
  </div>
);

// Services component
const Services = () => (
  <div className="container mx-auto pt-16 p-4">
    <h1 className="text-2xl font-bold mb-4">Our Services</h1>
    <p className="text-gray-600">This is the Services page</p>
  </div>
);

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/app/*" element={<AppLayout />} />
        
        {/* Routes without Navbar and Footer */}
        <Route path="/business-evaluation" element={<BusinessEvaluation />} />
        
        {/* Routes with Navbar and Footer */}
        <Route
          path="*"
          element={
            <div className="flex flex-col min-h-screen">
              <Navbar />
              <main className="flex-grow pt-12">
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/services" element={<Services />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/who-we-are" element={<WhoWeAre />} />
                  <Route path="/use-cases" element={<UseCases />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route path="/review" element={<Review />} />
                  <Route path="/billing" element={<Billing />} />
                </Routes>
              </main>
              <Footer />
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;