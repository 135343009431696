import React from 'react';

// CompanyCard component
const CompanyCard = ({ title, subtitle }) => {
  // Generate random width class (between 1/4 and 1/2 of container)
  const widthClasses = ['md:w-1/4', 'md:w-1/3', 'md:w-1/2'];
  const randomWidth = widthClasses[Math.floor(Math.random() * widthClasses.length)];
  
  // Generate random height (between 200px and 400px)
  const randomHeight = Math.floor(Math.random() * (400 - 200) + 200);

  // Determine text size classes based on card width
  const getTextClasses = () => {
    switch (randomWidth) {
      case 'md:w-1/2':
        return {
          title: 'text-2xl md:text-3xl lg:text-4xl',
          subtitle: 'text-lg md:text-xl'
        };
      case 'md:w-1/3':
        return {
          title: 'text-xl md:text-2xl lg:text-3xl',
          subtitle: 'text-base md:text-lg'
        };
      default: // md:w-1/4
        return {
          title: 'text-lg md:text-xl',
          subtitle: 'text-sm md:text-base'
        };
    }
  };

  const textClasses = getTextClasses();

  return (
    <div className={`p-4 ${randomWidth} w-full`}>
      <div 
        className="group relative overflow-hidden rounded-lg border border-[#f5f5f7]/10 bg-[#161617] p-6 transition-all duration-300 hover:border-[#FF7124]/50"
        style={{ height: `${randomHeight}px` }}
      >
        {/* Hover effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-[#FF7124]/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        
        {/* Content */}
        <div className="relative z-10">
          <h3 className={`font-semibold text-[#f5f5f7] mb-2 leading-tight ${textClasses.title}`}>
            {title}
          </h3>
          <p className={`text-[#f5f5f7]/60 leading-relaxed ${textClasses.subtitle}`}>
            {subtitle}
          </p>
        </div>
      </div>
    </div>
  );
};

const UseCases = () => {
  // This will be your array of company data
  const companies = [
    { "title": "Alaska Airlines", "subtitle": "Developing natural language search to create a conversational experience for travelers, streamlining booking and enhancing customer experience." },
    { "title": "Bennie Health", "subtitle": "Uses Vertex AI for an employee health benefits platform, providing insights and streamlining data management for efficiency and decision-making." },
    { "title": "Beyond 12", "subtitle": "Developed an AI-powered college coach for scalable coaching of first-generation students via text, app, and web." },
    { "title": "CareerVillage", "subtitle": "Building 'Coach' app with over 35 career development activities to help job seekers, aiming for more than 100 activities by next year." },
    { "title": "Character.ai", "subtitle": "Realistic conversational chat platform using Google Cloud AI for managing terabytes of conversations daily." },
    { "title": "Click Therapeutics", "subtitle": "Develops digital therapeutics and uses Gemini to transform complex data into insights for clinical trials." },
    { "title": "Formula E", "subtitle": "Summarizes race commentary into a short podcast in any language, integrating driver data and storylines." },
    { "title": "General Motors’ OnStar", "subtitle": "Enhanced with AI-powered virtual assistant features for intent recognition and assistance." },
    { "title": "Gojek", "subtitle": "Launched 'Dira by GoTo AI,' an AI voice assistant in Bahasa Indonesia integrated into GoPay for easier transactions." },
    { "title": "GroupBy", "subtitle": "Developed an AI-powered Search and Discovery Platform for ecommerce to optimize revenue and strengthen brand loyalty." },
    { "title": "Hotelplan Suisse", "subtitle": "Built a chatbot with travel expertise to answer inquiries in real-time and plans to create AI-generated travel content." },
    { "title": "Justicia Lab", "subtitle": "Developing an AI assistant to help asylum seekers and immigrants with legal processes and document analysis." },
    { "title": "Mercado Libre", "subtitle": "Incorporated semantic search to improve product recommendations for consumers in Latin America." },
    { "title": "Motorola", "subtitle": "Moto AI leverages Gemini for productivity and creative tools like conversation summaries and image creation." },
    { "title": "mRelief", "subtitle": "Built an SMS-accessible chatbot to simplify the application for the SNAP food assistance program in the U.S." },
    { "title": "Personal AI", "subtitle": "Offers a personal language model for individuals and brands to control their interactive messaging experiences." },
    { "title": "PODS", "subtitle": "Created 'World’s Smartest Billboard' using Gemini, generating real-time adaptable ads across NYC neighborhoods." },
    { "title": "Quora", "subtitle": "Developed Poe, a generative AI platform for discovering and interacting with various AI-powered bots." },
    { "title": "ScottsMiracle-Gro", "subtitle": "Built an AI agent on Vertex AI for personalized gardening advice and product recommendations." },
    { "title": "Snap", "subtitle": "Integrated Gemini's multimodal capability into 'My AI' chatbot, boosting engagement with users." },
    { "title": "Tabiya", "subtitle": "Developed Compass, a conversational interface to help youth find employment by matching skills with roles." },
    { "title": "Telecom Italia (TIM)", "subtitle": "Implemented a voice agent that increased efficiency in handling customer calls by 20%." },
    { "title": "UPS Capital", "subtitle": "Launched DeliveryDefense Address Confidence to improve delivery success predictions using machine learning." },
    { "title": "Volkswagen of America", "subtitle": "Built a virtual assistant in the myVW app, enabling users to ask questions and explore their car’s features." },
    { "title": "ADT", "subtitle": "Developing a customer agent to assist customers in selecting, ordering, and setting up home security." },
    { "title": "Alaska Airlines (Personalized Search)", "subtitle": "Building a hyper-personalized travel search for customer engagement and loyalty through AI." },
    { "title": "Best Buy", "subtitle": "Launching a generative AI-powered assistant for troubleshooting and managing orders and subscriptions." },
    { "title": "Central Texas Regional Mobility Authority", "subtitle": "Using Vertex AI to modernize transportation operations for better efficiency." },
    { "title": "Etsy", "subtitle": "Uses Vertex AI for optimizing search recommendations and ads to improve listing suggestions for buyers." },
    { "title": "Golden State Warriors", "subtitle": "Using AI to enhance fan experience content on the Chase Center app." },
    { "title": "IHG Hotels & Resorts", "subtitle": "Developing an AI chatbot in the IHG One Rewards app to help guests plan vacations." },
    { "title": "ING Bank", "subtitle": "Developed a gen-AI chatbot for workers to enhance self-service and improve customer query responses." },
    { "title": "Magalu", "subtitle": "Using Vertex AI for 'Lu's Brain,' an interactive agent with a large following across social media." },
    { "title": "Mercedes Benz", "subtitle": "Building a gen AI-powered sales assistant for e-commerce, enhancing AI in marketing and call centers." },
    { "title": "Oppo/OnePlus", "subtitle": "Integrating Google Cloud AI for customer experience features like conversation summaries and AI tools." },
    { "title": "Samsung", "subtitle": "Deploying Gemini Pro and Imagen 2 for features like text summarization and image editing on Galaxy S24." },
    { "title": "Minnesota Division of Driver and Vehicle Services", "subtitle": "Provides real-time translation for non-English speakers in licensing and services." },
    { "title": "Pepperdine University", "subtitle": "Uses Gemini in Google Meet for real-time translated captioning and notes across multiple languages." },
    { "title": "Sutherland", "subtitle": "Combining human expertise and AI to enhance client-facing teams with suggested responses and insights." },
    { "title": "Target", "subtitle": "Utilizes Google Cloud AI in the Target app and Target.com for personalized offers and pickup solutions." },
    { "title": "Tokopedia", "subtitle": "Using Vertex AI to improve data quality, increasing unique products sold by 5%." },
    { "title": "US News", "subtitle": "Saw an improvement in engagement metrics like click-through rate after implementing Vertex AI Search." },
    { "title": "IntesaSanpaolo, Macquarie Bank, and Scotiabank", "subtitle": "Exploring gen AI for productivity and operational efficiency in banking." },
    { "title": "2bots", "subtitle": "Offers chatbots and virtual agents built with Google Cloud AI, transforming customer interactions." },
    { "title": "Augment", "subtitle": "Building an AI personal assistant for enhanced note-taking and personal information organization." },
    { "title": "Bayes Impact", "subtitle": "Builds AI for nonprofits, with CaseAI saving caseworkers 25 hours per week by drafting action plans." },
    { "title": "Bell Canada", "subtitle": "Developed customizable contact center solutions with AI-powered agents and sentiment analysis." },
    { "title": "Best Buy (Contact Center)", "subtitle": "Real-time conversation summaries reduce call times and improve customer and agent satisfaction." },
    { "title": "Camanchaca", "subtitle": "Created Elon, a virtual assistant for efficient customer service through digital channels." },
    { "title": "Certify OS", "subtitle": "Automates credentialing and monitoring for healthcare providers to save time and improve workflows." },
    { "title": "Cost Plus Drugs", "subtitle": "Uses Gemini for workflow efficiencies like auto-formatting of lab results and compliance documentation." },
    { "title": "Dun & Bradstreet", "subtitle": "Built an email tool with Gemini for tailored customer communications and intelligent search capabilities." },
    { "title": "England’s Football Association", "subtitle": "Training Vertex AI to summarize scouting reports for talent discovery in national teams." },
    { "title": "Fireflies.ai", "subtitle": "Transcribes, summarizes, and analyzes meetings to enhance team collaboration and information sharing." },
    { "title": "Fluna", "subtitle": "Automated legal agreement drafting with 92% data extraction accuracy using Vertex AI and Document AI." },
    { "title": "Hemominas", "subtitle": "Developed a chatbot for donor search and scheduling, aiming to optimize blood supply management." },
    { "title": "Hiscox", "subtitle": "Created AI-enhanced underwriting model, reducing quoting time for complex risks." },
    { "title": "LiveX AI", "subtitle": "Delivers AI agents for enhanced product education, customer support, and personalized experiences." },
    { "title": "Opportunity@Work", "subtitle": "Uses gen AI to help employers identify 'STAR' job candidates from alternative backgrounds." },
    { "title": "Quantum Metric", "subtitle": "Introduced Felix AI to simplify digital analytics by summarizing user sessions for customer service." },
    { "title": "Randstad", "subtitle": "Using Gemini for Workspace to foster a culturally inclusive work environment and reduce sick days." },
    { "title": "Sprinklr", "subtitle": "Integrated Sprinklr AI+ for customer experience management with gen-AI capabilities." },
    { "title": "Thomson Reuters", "subtitle": "Added Gemini Pro for faster document processing."},
    ]  
  return (
    <div className="min-h-screen bg-[#161617] pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title Section */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h1 className="text-4xl md:text-5xl font-bold text-[#f5f5f7] mb-6">
            AI Implementation Success Stories
          </h1>
          <p className="text-[#f5f5f7]/60 text-lg md:text-xl">
            Explore how hundreds of companies transformed their operations through strategic AI adoption, achieving unprecedented growth and efficiency.
          </p>
        </div>

        {/* Cards Grid */}
        <div className="flex flex-wrap -mx-4">
          {companies.map((company, index) => (
            <CompanyCard
              key={index}
              title={company.title}
              subtitle={company.subtitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default UseCases; 