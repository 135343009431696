import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Partners2 from './Partners2';
import Process from './Process';
import Solutions from './Solutions';
import Work from './Work';
import ComparisonTable from './ComparisonTable';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen">
      {/* Hero Section with Gradient */}
      <section 
        className="min-h-screen relative"
        style={{
          backgroundImage: 'linear-gradient(180deg, rgba(255, 113, 36, 0.8) 0%, #161617 90%)',
        }}
      >
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            {/* Left side - Text Content */}
            <div className="text-center lg:text-left lg:max-w-xl">
              {/* Small pill/badge at top */}
              <div className="inline-block mb-4 lg:mb-6 px-4 py-1 rounded-full border border-[#f5f5f7] bg-[#161617] backdrop-blur-sm">
                <span className="text-sm text-[#f5f5f7]/80">
                  Transforming businesses with AI
                </span>
              </div>

              {/* Main heading */}
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 lg:mb-6 tracking-tight text-[#f5f5f7]">
                Turn AI Potential into 
                <br />
                Practical Results for Your Business
              </h1>

              {/* Subheading */}
              <p className="text-lg lg:text-xl mb-8 lg:mb-10 text-[#f5f5f7]/80">
                Simplify complex steps, achieve measurable results, and scale your business with actionable guidance every step of the way.
              </p>

              {/* Buttons */}
              <div className="flex flex-col md:flex-row justify-center lg:justify-start items-center space-y-4 md:space-y-0 md:space-x-4">
                <Link
                  to="/signup"
                  className="w-full md:w-auto inline-block bg-accent text-white px-6 lg:px-8 py-2.5 lg:py-3 rounded-lg
                    text-base lg:text-lg font-medium hover:bg-accent/90 transition-colors"
                >
                  Build Your Roadmap
                </Link>
                <Link
                  to="/who-we-are"
                  className="w-full md:w-auto inline-block border-2 border-accent text-accent px-8 py-[10px] rounded-lg
                    text-lg font-medium hover:bg-accent/10 transition-colors"
                >
                  Request Demo
                </Link>
              </div>
            </div>

            {/* Right side - Hero Image */}
            <div className="hidden lg:block">
              <img 
                src="/hero.svg" 
                alt="AI Business Transformation" 
                className="w-full h-auto max-w-[600px] mx-auto"
              />
            </div>
          </div>
        </div>

        {/* Optional: Add subtle pattern overlay */}
        <div className="absolute inset-0 bg-grid-pattern opacity-5 pointer-events-none" />
      </section>

      {/* Partners Section */}
      <div id="partners">
        <Partners2 />
      </div>

      {/* Process Section */}
      <div id="process">
        <Process />
      </div>

      {/* Solutions Section */}
      <div id="solutions">
        <Solutions />
      </div>

      <div id="work">
        <Work />
      </div>

      <div id="comparison">
        <ComparisonTable />
      </div>

      <div className="mt-20 border border-[#f5f5f7]/10 rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold text-[#f5f5f7] mb-4">
            Ready to Transform Your Business with AI?
          </h2>
          <p className="text-xl text-[#f5f5f7]/60 mb-8">
            Get started today and receive your personalized AI implementation roadmap.
          </p>
          <button 
            onClick={() => navigate('/signup')}
            className="bg-[#FF7124] text-[#f5f5f7] font-bold py-3 px-8 rounded-lg hover:bg-[#FF7124]/90 
              transition duration-300 flex items-center justify-center mx-auto"
          >
            Get Started Now
            <FaArrowRight className="ml-2" />
          </button>
        </div>
    </div>
  );
};

export default HomePage; 