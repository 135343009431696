import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';
import { 
  CiLogin, 
  CiFileOn, 
  CiRoute, 
  CiSettings, 
  CiCloudOn 
} from "react-icons/ci";

const ProcessCard = ({ step }) => {
  const cardRef = useRef(null);
  const isInView = useInView(cardRef);

  // Icon mapping object
  const iconMap = {
    '01': CiLogin,
    '02': CiFileOn,
    '03': CiRoute,
    '04': CiSettings,
    '05': CiCloudOn,
  };

  const IconComponent = iconMap[step.number];

  return (
    <div ref={cardRef} className="relative group h-full">
      {/* Glow effect container */}
      <div className={`absolute -inset-0.5 bg-gradient-to-r from-[#FF7124] to-[#FF7124]/60 
        rounded-lg blur transition-opacity duration-500
        ${isInView ? 'md:opacity-0 opacity-100' : 'opacity-0'} 
        md:group-hover:opacity-100`}>
      </div>
      
      {/* Card content */}
      <div className={`relative h-full p-6 rounded-lg border border-[#f5f5f7]/10 
        bg-[#161617] backdrop-blur-sm flex flex-col transition-all duration-500
        ${isInView ? 'transform translate-y-0' : 'transform translate-y-4'}`}>
        {/* Icon instead of placeholder image */}
        <div className="mb-6 h-32 w-full rounded-lg flex items-center justify-center">
          <IconComponent className="w-20 h-20 text-[#FF7124]" />
        </div>
        <div className="flex items-center mb-4">
          <span className="text-accent text-sm font-medium mr-2">
            {step.number}.
          </span>
          <h3 className="text-[#f5f5f7] text-lg font-medium">
            {step.title}
          </h3>
        </div>
        <p className="text-[#f5f5f7]/60 text-sm flex-grow">
          {step.description}
        </p>
      </div>
    </div>
  );
};

const Process = () => {
  const steps = [
    {
      number: '01',
      title: 'Log In to Your ModiAI Account',
      description: 'Start by logging in securely to access your personalized AI Implementation dashboard.'
    },
    {
      number: '02',
      title: 'Complete the Quick Onboarding Form',
      description: 'Answer a few key questions about your industry, department focus, business goals, and any specific metrics you\'d like to improve. This helps us tailor the roadmap specifically for your business.'
    },
    {
      number: '03',
      title: 'Receive Your Customized AI Roadmap',
      description: 'Instantly view your AI Implementation Roadmap, which outlines each phase with actionable steps, timeline guidance, and milestone checklists—all crafted based on your unique business needs.'
    },
    {
      number: '04',
      title: 'Adjust & Track Your Progress',
      description: 'As you work through each phase, mark tasks as complete, monitor KPIs, and follow suggested adjustments. Your dashboard will keep track of your progress and notify you of upcoming milestones.'
    },
    {
      number: '05',
      title: 'Implement & Scale with Confidence',
      description: 'With a clear implementation roadmap, integrate AI in stages, train your team, and monitor performance. By following the roadmap, you\'ll achieve sustainable AI adoption and be ready to scale further.'
    }
  ];

  return (
    <section className="py-24 bg-[#161617] relative overflow-hidden">
      {/* Background glow effect */}
      <div className="absolute bottom-0 right-0 w-1/2 h-1/2">
        <div className="absolute bottom-0 right-0 w-full h-full bg-accent/20 blur-[100px] rounded-full" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Title Section */}
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-[#f5f5f7] mb-4">
            How it works
          </h2>
          <p className="text-[#f5f5f7]/60 text-lg max-w-2xl mx-auto">
            Our streamlined process ensures efficient implementation and seamless integration
          </p>
        </div>

        {/* Process Steps Grid */}
        <div className="grid grid-cols-1 gap-8">
          {/* First row - 3 cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {steps.slice(0, 3).map((step) => (
              <ProcessCard key={step.number} step={step} />
            ))}
          </div>

          {/* Second row - 2 cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {steps.slice(3).map((step) => (
              <ProcessCard key={step.number} step={step} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Process; 