import React, { useState, useEffect } from 'react';
import { FaUserCircle, FaExclamationCircle, FaBullseye, FaTools, 
         FaHeart, FaGlobeAmericas, FaClock, FaBars, FaTimes,
         FaSignOutAlt, FaArrowLeft } from 'react-icons/fa';
import OrganizationProfile from './dashboard/steps/OrganizationProfile';
import BusinessChallenges from './dashboard/steps/BusinessChallenges';
import BusinessGoals from './dashboard/steps/BusinessGoals';
import TechnologyInfrastructure from './dashboard/steps/TechnologyInfrastructure';
import CustomerExperience from './dashboard/steps/CustomerExperience';
import CompetitivePositioning from './dashboard/steps/CompetitivePositioning';
import ImplementationReadiness from './dashboard/steps/ImplementationReadiness';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

const BusinessEvaluation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    industry: '',
    location: '',
    companySize: '',
    revenue: '',
    operationalChallenges: {},
    customerChallenges: {},
    growthBlockers: {},
    timeSpent: '',
    supportLevel: '',
    operationalChallengesOther: '',
    customerChallengesOther: '',
    growthBlockersOther: '',
    businessObjectives: {},
    efficiencyImprovements: {},
    costPriority: '',
    revenueTarget: '',
    workflowChange: '',
    businessObjectivesOther: '',
    efficiencyImprovementsOther: '',
    currentTools: {},
    itTeam: '',
    aiExpertise: '',
    dataStorage: '',
    analyticsStrategy: '',
    currentToolsOther: '',
    satisfactionImportance: '',
    experienceGoals: {},
    feedbackFrequency: '',
    satisfactionMetrics: {},
    supportChannels: {},
    experienceGoalsOther: '',
    satisfactionMetricsOther: '',
    supportChannelsOther: '',
    competitors: '',
    differentiators: {},
    marketPosition: '',
    marketShare: '',
    marketExpansion: '',
    differentiatorsOther: '',
    implementationTimeline: '',
    projectBudget: '',
    availableResources: {},
    executiveSupport: '',
    implementationSupport: '',
    availableResourcesOther: ''
  });

  const navigate = useNavigate();

  const steps = [
    { id: 1, title: 'Organization Profile', icon: FaUserCircle },
    { id: 2, title: 'Business Challenges', icon: FaExclamationCircle },
    { id: 3, title: 'Business Goals', icon: FaBullseye },
    { id: 4, title: 'Technology & Infrastructure', icon: FaTools },
    { id: 5, title: 'Customer Experience', icon: FaHeart },
    { id: 6, title: 'Competitive Positioning', icon: FaGlobeAmericas },
    { id: 7, title: 'Implementation Readiness', icon: FaClock }
  ];

  const stepDescriptions = {
    1: "Let's start by gathering some basic information about your organization to help us understand your business better.",
    2: "Help us understand the challenges your business is currently facing so we can identify the right AI solutions.",
    3: "Define your business objectives and goals to ensure our AI implementation aligns with your vision.",
    4: "Tell us about your current technology setup to determine the best integration approach.",
    5: "Share insights about your customer experience goals to enhance service delivery through AI.",
    6: "Help us understand your market position to develop competitive AI strategies.",
    7: "Let's assess your readiness for AI implementation and plan the next steps."
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <OrganizationProfile formData={formData} setFormData={setFormData} />;
      case 2:
        return <BusinessChallenges formData={formData} setFormData={setFormData} />;
      case 3:
        return <BusinessGoals formData={formData} setFormData={setFormData} />;
      case 4:
        return <TechnologyInfrastructure formData={formData} setFormData={setFormData} />;
      case 5:
        return <CustomerExperience formData={formData} setFormData={setFormData} />;
      case 6:
        return <CompetitivePositioning formData={formData} setFormData={setFormData} />;
      case 7:
        return <ImplementationReadiness formData={formData} setFormData={setFormData} />;
      default:
        return null;
    }
  };

  const handleFinish = () => {
    navigate('/review', { state: { formData } });
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handleStepClick = (stepId) => {
    setCurrentStep(stepId);
    setIsSidebarOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="grid grid-cols-8 min-h-screen bg-[#161617]">
      {/* Mobile Header */}
      <div className="md:hidden fixed top-0 left-0 right-0 bg-[#1c1c1d] z-50 px-4 py-3 
                    flex items-center justify-between border-b border-[#f5f5f7]/10">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate('/dashboard')}
            className="flex items-center text-[#f5f5f7]/80 hover:text-[#f5f5f7] 
                     transition-colors duration-200"
          >
            <FaArrowLeft className="mr-2 text-lg" />
            <span className="text-sm font-medium">Home</span>
          </button>
        </div>

        {/* Only show menu toggle button in header */}
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="flex items-center text-[#f5f5f7]/80 hover:text-[#f5f5f7] 
                   transition-colors duration-200"
        >
          {isSidebarOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
      </div>

      {/* Blur Overlay */}
      <div 
        className={`fixed inset-0 bg-black/30 backdrop-blur-sm transition-opacity duration-300 md:hidden
                  ${isSidebarOpen ? 'opacity-100 z-30' : 'opacity-0 pointer-events-none'}`}
        onClick={() => setIsSidebarOpen(false)}
      />

      {/* Left Sidebar - Fixed position */}
      <div className={`fixed md:fixed top-0 left-0 h-screen w-[280px] md:w-[250px] bg-[#1c1c1d] z-40
                      transition-transform duration-300 ease-in-out
                      ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
                      md:translate-x-0 flex flex-col`}>
        {/* Sidebar Header */}
        <div className="p-6 border-b border-[#f5f5f7]/10">
          {/* Mobile Close Button */}
          <div className="md:hidden mb-4">
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="flex items-center text-[#f5f5f7]/80 hover:text-[#f5f5f7] 
                       transition-colors duration-200 mb-6"
            >
              <FaTimes className="text-xl" />
            </button>
          </div>
          
          <h1 className="text-xl md:text-2xl font-bold text-[#f5f5f7] mb-1">Welcome to Modi AI</h1>
          <p className="text-sm text-[#f5f5f7]/60">Complete the 7 steps to get started</p>
        </div>
        
        {/* Navigation Steps */}
        <div className="flex-1 overflow-y-auto">
          {steps.map((step) => (
            <button
              key={step.id}
              onClick={() => handleStepClick(step.id)}
              className={`w-full flex items-center px-6 py-3 text-left ${
                currentStep === step.id
                  ? 'bg-[#FF5733]/20 border-l-4 border-[#FF5733]'
                  : 'text-[#f5f5f7]/80 hover:bg-[#FF5733]/10'
              }`}
            >
              <step.icon 
                className={`mr-3 text-xl ${
                  currentStep === step.id ? 'text-[#FF5733]' : 'text-[#f5f5f7]/60'
                }`} 
              />
              <span className={`${
                currentStep === step.id ? 'text-[#FF5733] font-medium' : 'text-[#f5f5f7]/80'
              }`}>
                {step.title}
              </span>
            </button>
          ))}
        </div>

        {/* Navigation and Sign Out Buttons */}
        <div className={`p-4 border-t border-[#f5f5f7]/10 ${isSidebarOpen ? 'block' : 'hidden md:block'}`}>
          <button
            onClick={() => {
              setCurrentStep(prev => prev - 1);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
            className="w-full flex items-center px-4 py-3 mb-2 !text-[#f5f5f7] 
                     hover:bg-[#FF5733]/10 rounded-lg transition-colors duration-200"
            disabled={currentStep === 1}
          >
            <FaArrowLeft className="mr-3 text-xl !text-[#f5f5f7]" />
            <span className="!text-[#f5f5f7]">Back</span>
          </button>
          
          <button
            onClick={handleSignOut}
            className="w-full flex items-center px-4 py-3 !text-[#f5f5f7] 
                     hover:bg-[#FF5733]/10 rounded-lg transition-colors duration-200"
          >
            <FaSignOutAlt className="mr-3 text-xl !text-[#f5f5f7]" />
            <span className="!text-[#f5f5f7]">Sign Out</span>
          </button>
        </div>
      </div>

      {/* Main Content Area - Added padding for medium screens */}
      <div className={`col-span-8 md:col-start-3 md:col-span-6 min-h-screen 
                      transition-all duration-300
                      md:pl-8 lg:pl-0
                      ${isSidebarOpen ? 'blur-sm md:blur-none' : ''}`}>
        <div className="h-full flex flex-col items-center justify-start 
                      px-4 md:px-8 pt-20 md:pt-12">
          {/* Content Container */}
          <div className="w-full max-w-2xl mx-auto">
            {/* Step Description */}
            <div className="mb-8">
              <h2 className="text-2xl md:text-3xl font-bold text-[#f5f5f7] mb-3">
                Step {currentStep} of 7
              </h2>
              <p className="text-[#f5f5f7]/80 text-lg leading-relaxed">
                {stepDescriptions[currentStep]}
              </p>
            </div>

            {/* Progress Bar */}
            <div className="mb-6">
              <div className="h-2 w-full bg-[#1c1c1d] rounded-full">
                <div 
                  className="h-2 bg-[#FF5733] rounded-full transition-all duration-300"
                  style={{ width: `${(currentStep / steps.length) * 100}%` }}
                ></div>
              </div>
              <div className="mt-2 text-right text-sm text-[#f5f5f7]/60">
                {Math.round((currentStep / steps.length) * 100)}% Complete
              </div>
            </div>

            {/* Content Area */}
            <div className="bg-[#1c1c1d] rounded-lg shadow-lg p-6 mb-8">
              <h2 className="text-xl md:text-2xl font-semibold text-[#f5f5f7] mb-6">
                {steps.find(step => step.id === currentStep)?.title}
              </h2>
              
              {/* Form Fields */}
              <div className="w-full">
                {renderStepContent()}
              </div>

              {/* Navigation Buttons */}
              <div className="flex justify-between mt-8">
                <button
                  onClick={() => {
                    setCurrentStep(prev => prev - 1);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className="px-6 py-2 border border-[#f5f5f7]/10 rounded-lg !text-[#f5f5f7] 
                           hover:bg-[#161617] transition-colors duration-200"
                  disabled={currentStep === 1}
                >
                  Back
                </button>
                <button
                  onClick={currentStep === steps.length ? handleFinish : () => {
                    setCurrentStep(prev => prev + 1);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }}
                  className="px-6 py-2 bg-[#FF5733] text-[#f5f5f7] rounded-lg 
                           hover:bg-[#FF5733]/90 transition-colors duration-200"
                >
                  {currentStep === steps.length ? 'Finish' : 'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessEvaluation;
