import React from 'react';

const Work = () => {
  const projects = [
    {
      year: '2024',
      title: 'AI-Powered Success with ModiAI',
      description: 'Achieving a 6-figure valuation and first clients in just 30 days through AI insights.',
      link: 'https://simplified.com/blog/us/casestudy-ai-bootcamp'
    },
    {
      year: '2024',
      title: 'Modi AI: The Cash-Printing Machine',
      description: 'Modi AI delivers a personalised, revenue roadmap with AI within 24 hours, attracting significant investment interest.',
      link: 'https://www.boltchatai.com/empowering-the-next-generation-of-ai-entrepreneurs/'
    },
    {
      year: '2024',
      title: 'Empowering Youth with AI',
      description: 'Achieved 1,000+ unique views, 3,500+ impressions, and 8,000% growth, creating 3+ business opportunities weekly.',
      link: 'https://www.blackcube.digital/blog/ai-empowering-youth-bootcamp'
    }
  ];

  return (
    <section className="py-24 bg-[#161617] relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Centered Title */}
        <h2 className="text-4xl md:text-5xl font-bold mb-20 text-center">
          <span className="text-[#f5f5f7]">Our Work</span>
        </h2>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* Left Column - Project List */}
          <div>
            <div className="space-y-10">
              {projects.map((project) => (
                <div 
                  key={project.title}
                  className="border-b border-[#f5f5f7]/10 pb-10 group"
                >
                  <div className="text-[#FF7124]/80 text-sm mb-2">
                    {project.year}
                  </div>
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-[#f5f5f7] text-xl md:text-2xl font-medium mb-2">
                        {project.title}
                      </h3>
                      <p className="text-[#f5f5f7]/60 text-sm">
                        {project.description}
                      </p>
                    </div>
                    <a 
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#FF7124] group-hover:opacity-100 transition-opacity duration-300"
                    >
                      <svg 
                        className="w-10 h-10 transform rotate-[-45deg] group-hover:rotate-0 transition-all duration-300"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M5 12h14" />
                        <path d="M12 5l7 7-7 7" />
                      </svg>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Right Column - Image */}
          <div className="relative flex justify-center">
            <div className="w-3/4 aspect-square rounded-2xl overflow-hidden">
              <img
                src="/completed.svg"
                alt="Completed tasks illustration"
                className="w-full h-full object-contain"
              />
              {/* Gradient overlay */}
              <div className="absolute inset-0 bg-gradient-to-br from-transparent to-[#161617]/80" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Work;