import React from 'react';
import { 
  FaBuilding,
  FaIndustry, 
  FaGlobe,
  FaUsers,
  FaMoneyBillWave
} from 'react-icons/fa';

const OrganizationProfile = ({ formData, setFormData }) => {
  const industries = [
    'Manufacturing',
    'Retail',
    'Healthcare',
    'Finance',
    'Technology',
    'Education',
    'Hospitality',
    'Construction',
    'Transportation',
    'Other'
  ];

  const companySizes = [
    'Micro (1-10 employees)',
    'Small (11-50 employees)',
    'Medium (51-200 employees)',
    'Large (201+ employees)'
  ];

  const revenueRanges = [
    'Less than $1M',
    '$1M - $10M',
    '$10M - $50M',
    '$50M - $100M',
    'Over $100M'
  ];

  const countries = [
    'United States',
    'United Kingdom',
    'Canada',
    'Australia',
    'Germany',
    'France',
    'Japan',
    'China',
    'India',
    'Brazil'
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  return (
    <div className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-[#f5f5f7]/80 mb-1">
          Company Name
        </label>
        <div className="relative">
          <input
            type="text"
            name="companyName"
            value={formData.companyName || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 pl-10 bg-[#161617] border border-[#f5f5f7]/10 rounded-lg 
                     text-[#f5f5f7] placeholder-[#f5f5f7]/40
                     focus:ring-2 focus:ring-[#FF5733] focus:border-[#FF5733]"
            placeholder="Enter company name"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FaBuilding className="h-5 w-5 text-[#f5f5f7]/60" />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-[#f5f5f7]/80 mb-1">
          Industry
        </label>
        <div className="relative">
          <select
            name="industry"
            value={formData.industry || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 pl-10 bg-[#161617] border border-[#f5f5f7]/10 rounded-lg 
                     text-[#f5f5f7] appearance-none
                     focus:ring-2 focus:ring-[#FF5733] focus:border-[#FF5733]"
          >
            <option value="">Select industry</option>
            {industries.map((industry) => (
              <option key={industry} value={industry}>
                {industry}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FaIndustry className="h-5 w-5 text-[#f5f5f7]/60" />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-[#f5f5f7]/80 mb-1">
          Location
        </label>
        <div className="relative">
          <select
            name="location"
            value={formData.location || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 pl-10 bg-[#161617] border border-[#f5f5f7]/10 rounded-lg 
                     text-[#f5f5f7] appearance-none
                     focus:ring-2 focus:ring-[#FF5733] focus:border-[#FF5733]"
          >
            <option value="">Select country</option>
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FaGlobe className="h-5 w-5 text-[#f5f5f7]/60" />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-[#f5f5f7]/80 mb-1">
          Company Size
        </label>
        <div className="relative">
          <select
            name="companySize"
            value={formData.companySize || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 pl-10 bg-[#161617] border border-[#f5f5f7]/10 rounded-lg 
                     text-[#f5f5f7] appearance-none
                     focus:ring-2 focus:ring-[#FF5733] focus:border-[#FF5733]"
          >
            <option value="">Select company size</option>
            {companySizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FaUsers className="h-5 w-5 text-[#f5f5f7]/60" />
          </div>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-[#f5f5f7]/80 mb-1">
          Annual Revenue
        </label>
        <div className="relative">
          <select
            name="revenue"
            value={formData.revenue || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 pl-10 bg-[#161617] border border-[#f5f5f7]/10 rounded-lg 
                     text-[#f5f5f7] appearance-none
                     focus:ring-2 focus:ring-[#FF5733] focus:border-[#FF5733]"
          >
            <option value="">Select revenue range</option>
            {revenueRanges.map((range) => (
              <option key={range} value={range}>
                {range}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <FaMoneyBillWave className="h-5 w-5 text-[#f5f5f7]/60" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationProfile; 