import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const currentTime = new Date().toLocaleTimeString('en-US', { 
    timeZone: 'CET',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });

  const leftColumn = [
    { text: '© 2024', className: 'text-[#FF7124]' },
    { text: 'support@modiai.net', href: 'mailto:support@modiai.net' },
    { text: '+383 (45) 949 355', href: 'tel:+38345949355' },
    { text: `Local time: ${currentTime} (CET)` }
  ];

  const centerColumn = [
    { text: 'Process', href: '/#process' },
    { text: 'Solutions', href: '/#solutions' },
    { text: 'Work', href: '/#work' },
    { text: 'Plans', href: '/#comparison' },
    { text: 'Partners', href: '/#partners' },
  ];

  const rightColumn = [
    { text: 'LinkedIn', href: 'https://linkedin.com' },
    { text: 'Twitter', href: 'https://twitter.com' },
    { text: 'Instagram', href: 'https://instagram.com' },
    { text: 'Dribbble', href: 'https://dribbble.com' }
  ];

  return (
    <footer className="py-24 bg-[#161617] relative overflow-hidden border-t border-[#f5f5f7]/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
          {/* Left Column */}
          <div className="space-y-4">
            {leftColumn.map((item, index) => (
              <div key={index}>
                {item.href ? (
                  <a 
                    href={item.href} 
                    className={`text-[#f5f5f7] hover:text-[#FF7124] transition-colors ${item.className || ''}`}
                  >
                    {item.text}
                  </a>
                ) : (
                  <span className={item.className || 'text-[#f5f5f7]'}>
                    {item.text}
                  </span>
                )}
              </div>
            ))}
          </div>

          {/* Center Column */}
          <div className="space-y-4">
            {centerColumn.map((item, index) => (
              <div key={index}>
                <Link 
                  to={item.href}
                  className="text-[#f5f5f7]/60 hover:text-[#f5f5f7] transition-colors flex items-center group"
                >
                  {item.text}
                  {item.icon && (
                    <span className="ml-1 transform group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform">
                      {item.icon}
                    </span>
                  )}
                </Link>
              </div>
            ))}
          </div>

          {/* Right Column */}
          <div className="space-y-4">
            {rightColumn.map((item, index) => (
              <div key={index}>
                <a 
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#f5f5f7]/60 hover:text-[#f5f5f7] transition-colors"
                >
                  {item.text}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
