import React from 'react';
import { 
  FaClock,
  FaMoneyBillWave,
  FaUsers,
  FaUserTie,
  FaHandsHelping
} from 'react-icons/fa';

const ImplementationReadiness = ({ formData, setFormData }) => {
  const timelineOptions = [
    '1-3 months',
    '3-6 months',
    '6-12 months',
    '12+ months'
  ];

  const budgetRanges = [
    'Under $10,000',
    '$10,000-$50,000',
    '$50,000-$100,000',
    'Over $100,000'
  ];

  const availableResources = [
    'Internal IT team',
    'Dedicated project team',
    'Limited resources',
    'Other'
  ];

  const executiveSupportOptions = [
    'Yes',
    'No',
    'Partially'
  ];

  const supportLevelOptions = [
    'Minimal',
    'Moderate',
    'Significant',
    'Extensive'
  ];

  const handleCheckboxChange = (category, value) => {
    setFormData(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [value]: !prev[category]?.[value]
      }
    }));
  };

  const handleRadioChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const CheckboxGroup = ({ title, icon: Icon, options, category }) => (
    <div>
      <div className="flex items-center gap-2 mb-4">
        <Icon className="h-5 w-5 text-[#f5f5f7]/60" />
        <label className="block text-sm font-medium text-[#f5f5f7]/80">
          {title}
        </label>
      </div>
      <div className="space-y-4 pl-7">
        {options.map((option) => (
          <div key={option} className="flex items-center group">
            <div className="relative flex items-center">
              <input
                type="checkbox"
                id={`${category}-${option}`}
                checked={formData[category]?.[option] || false}
                onChange={() => handleCheckboxChange(category, option)}
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-md border 
                         border-[#f5f5f7]/10 bg-[#161617] checked:border-[#FF5733] 
                         checked:bg-[#FF5733] hover:border-[#FF5733]/50
                         focus:ring-2 focus:ring-[#FF5733]/20 focus:ring-offset-2
                         focus:ring-offset-[#161617] transition-all duration-200"
              />
              <div className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 
                          -translate-y-1/2 text-white opacity-0 peer-checked:opacity-100 
                          transition-opacity">
                <svg className="h-3 w-3" viewBox="0 0 12 12" fill="none">
                  <path
                    d="M10 3L4.5 8.5L2 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <label
              htmlFor={`${category}-${option}`}
              className="ml-3 text-sm text-[#f5f5f7]/80 cursor-pointer 
                       group-hover:text-[#f5f5f7] transition-colors duration-200"
            >
              {option}
            </label>
            {option === 'Other' && formData[category]?.['Other'] && (
              <input
                type="text"
                placeholder="Please specify"
                className="ml-3 px-3 py-1.5 text-sm bg-[#161617] border border-[#f5f5f7]/10 
                         rounded-md text-[#f5f5f7] placeholder-[#f5f5f7]/40
                         focus:ring-1 focus:ring-[#FF5733] focus:border-[#FF5733]
                         transition-all duration-200"
                value={formData[`${category}Other`] || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  [`${category}Other`]: e.target.value
                }))}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const RadioGroup = ({ title, icon: Icon, options, name }) => (
    <div>
      <div className="flex items-center gap-2 mb-4">
        <Icon className="h-5 w-5 text-[#f5f5f7]/60" />
        <label className="block text-sm font-medium text-[#f5f5f7]/80">
          {title}
        </label>
      </div>
      <div className="space-y-4 pl-7">
        {options.map((option) => (
          <div key={option} className="flex items-center group">
            <div className="relative flex items-center">
              <input
                type="radio"
                id={`${name}-${option}`}
                name={name}
                value={option}
                checked={formData[name] === option}
                onChange={(e) => handleRadioChange(name, e.target.value)}
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-full border 
                         border-[#f5f5f7]/10 bg-[#161617] checked:border-[#FF5733] 
                         hover:border-[#FF5733]/50
                         focus:ring-2 focus:ring-[#FF5733]/20 focus:ring-offset-2 
                         focus:ring-offset-[#161617] transition-all duration-200"
              />
              <div className="pointer-events-none absolute left-1/2 top-1/2 h-2.5 w-2.5 
                          -translate-x-1/2 -translate-y-1/2 rounded-full bg-[#FF5733] 
                          opacity-0 peer-checked:opacity-100 transition-opacity duration-200">
              </div>
            </div>
            <label
              htmlFor={`${name}-${option}`}
              className="ml-3 text-sm text-[#f5f5f7]/80 cursor-pointer 
                       group-hover:text-[#f5f5f7] transition-colors duration-200"
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-10">
      <RadioGroup
        title="What is your preferred timeline for AI implementation?"
        icon={FaClock}
        options={timelineOptions}
        name="implementationTimeline"
      />

      <RadioGroup
        title="How would you describe your budget for this project?"
        icon={FaMoneyBillWave}
        options={budgetRanges}
        name="projectBudget"
      />

      <CheckboxGroup
        title="What resources are available for the project?"
        icon={FaUsers}
        options={availableResources}
        category="availableResources"
      />

      <RadioGroup
        title="Do you have executive support for AI initiatives?"
        icon={FaUserTie}
        options={executiveSupportOptions}
        name="executiveSupport"
      />

      <RadioGroup
        title="What is your preferred level of support during implementation?"
        icon={FaHandsHelping}
        options={supportLevelOptions}
        name="implementationSupport"
      />
    </div>
  );
};

export default ImplementationReadiness; 