import React from 'react';
import { FaCheck, FaStar, FaArrowRight } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const PricingCard = ({ title, price, description, features, cta, isPopular }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const formData = location.state?.formData;

  const handleSelectPlan = () => {
    const planDetails = {
      title,
      price,
      features
    };
    
    navigate('/billing', { 
      state: { 
        formData,
        planDetails 
      }
    });
  };

  return (
    <div className="relative group h-full">
      {/* Glow effect */}
      <div className="absolute -inset-0.5 bg-gradient-to-r from-[#FF7124] to-[#FF7124]/60 
        rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500">
      </div>
      
      <div className={`relative h-full flex flex-col p-8 rounded-lg border border-[#f5f5f7]/10 
        bg-[#161617] backdrop-blur-sm ${isPopular ? 'border-[#FF7124]/50' : ''}`}>
        {isPopular && (
          <div className="flex justify-center items-center mb-4">
            <span className="bg-[#FF7124] text-[#f5f5f7] text-xs font-bold px-3 py-1 rounded-full uppercase flex items-center">
              <FaStar className="mr-1" /> Most Popular
            </span>
          </div>
        )}
        
        {/* Card Header */}
        <div className="flex-none">
          <h3 className="text-2xl font-semibold text-[#f5f5f7] mb-2 text-center">{title}</h3>
          <div className="text-center mb-4">
            <span className="text-4xl font-bold text-[#FF7124]">{price}</span>
            <span className="text-[#f5f5f7]/60 text-sm ml-1">Starting from</span>
          </div>
          <p className="text-[#f5f5f7]/60 mb-6 text-center min-h-[60px]">{description}</p>
        </div>
        
        {/* Features List */}
        <div className="flex-grow">
          <ul className="space-y-4 mb-8">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start">
                <FaCheck className="text-[#FF7124] mt-1 mr-2 flex-shrink-0" />
                <span className="text-[#f5f5f7]/60">{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        
        {/* Button */}
        <div className="flex-none">
          <button 
            onClick={handleSelectPlan}
            className={`w-full py-3 px-4 rounded-lg transition duration-200 
              ${isPopular 
                ? 'bg-[#FF7124] text-[#f5f5f7] hover:bg-[#FF7124]/90' 
                : 'bg-[#f5f5f7]/5 text-[#f5f5f7] hover:bg-[#f5f5f7]/10'}`}
          >
            {cta || 'Get Started'}
          </button>
        </div>
      </div>
    </div>
  );
};

const FeatureRow = ({ feature, basic_plan, pro_plan, enterprise_plan }) => (
  <tr className="border-b border-[#f5f5f7]/10 hover:bg-[#f5f5f7]/5">
    <td className="py-4 px-6 text-left text-lg text-[#f5f5f7]/60">{feature}</td>
    <td className="py-4 px-6 text-center">
      {basic_plan === true ? <FaCheck className="text-[#FF7124] mx-auto text-xl" /> : 
       typeof basic_plan === 'string' ? <span className="text-lg text-[#f5f5f7]/60">{basic_plan}</span> :
       <span className="text-lg text-[#f5f5f7]/60">-</span>}
    </td>
    <td className="py-4 px-6 text-center">
      {pro_plan === true ? <FaCheck className="text-[#FF7124] mx-auto text-xl" /> : 
       typeof pro_plan === 'string' ? <span className="text-lg text-[#f5f5f7]/60">{pro_plan}</span> :
       <span className="text-lg text-[#f5f5f7]/60">-</span>}
    </td>
    <td className="py-4 px-6 text-center">
      {enterprise_plan === true ? <FaCheck className="text-[#FF7124] mx-auto text-xl" /> : 
       typeof enterprise_plan === 'string' ? <span className="text-lg text-[#f5f5f7]/60">{enterprise_plan}</span> :
       <span className="text-lg text-[#f5f5f7]/60">-</span>}
    </td>
  </tr>
);

const Pricing = () => {
  const navigate = useNavigate();
  const features_and_benefits = [
    {
      "feature": "Customized AI Implementation Roadmap",
      "basic_plan": true,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Industry-Specific Insights",
      "basic_plan": true,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Department-Specific AI Strategies",
      "basic_plan": false,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Step-by-Step Integration Tips",
      "basic_plan": true,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "AI Use Cases for ROI",
      "basic_plan": false,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Competitor and Market Analysis",
      "basic_plan": false,
      "pro_plan": false,
      "enterprise_plan": true
    },
    {
      "feature": "Long-Term Scalability Roadmap",
      "basic_plan": false,
      "pro_plan": false,
      "enterprise_plan": true
    },
    {
      "feature": "1-Hour Consultation with AI Expert",
      "basic_plan": false,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "3-Hour Strategy Planning Session",
      "basic_plan": false,
      "pro_plan": false,
      "enterprise_plan": true
    },
    {
      "feature": "Resource Recommendations",
      "basic_plan": true,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Expanded Resource Library",
      "basic_plan": false,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Email Support (Post-Delivery)",
      "basic_plan": "30 Days",
      "pro_plan": "60 Days",
      "enterprise_plan": "90 Days"
    },
    {
      "feature": "Priority Support",
      "basic_plan": false,
      "pro_plan": true,
      "enterprise_plan": true
    },
    {
      "feature": "Premium Access to Future Updates",
      "basic_plan": false,
      "pro_plan": false,
      "enterprise_plan": true
    }
  ];

  return (
    <section className="pt-8 mt-0 bg-[#161617] relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-bold text-center mb-4 text-[#f5f5f7]">
          ModiAI AI Implementation Roadmap
        </h1>
        <p className="text-xl text-center text-[#f5f5f7]/60 mb-12">
          Tailored AI solutions for businesses of all sizes. Select the plan that fits your company's needs and budget.
        </p>

        {/* Pricing Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-stretch">
          <PricingCard
            title="Basic Plan"
            price="$1,999"
            description="Best for small businesses or startups seeking a clear and actionable AI roadmap to improve efficiency and decision-making."
            features={[
              "Customized AI Implementation Roadmap",
              "Industry-specific insights and strategies",
              "Step-by-step AI integration tips",
              "Access to resource recommendations",
              "30 days of email support post-delivery"
            ]}
            cta="Start with Basic"
          />

          <PricingCard
            title="Pro Plan"
            price="$10,000"
            description="Perfect for mid-sized businesses looking to leverage AI across multiple departments to drive growth and efficiency."
            features={[
              "Everything in the Basic Plan",
              "Department-specific AI strategies",
              "Advanced AI use cases tailored for higher ROI",
              "Expanded resource library for integration",
              "1-hour virtual consultation with an AI expert",
              "60 days of priority email support"
            ]}
            cta="Go Pro"
            isPopular={true}
          />

          <PricingCard
            title="Enterprise Plan"
            price="$50,000"
            description="Tailored for large enterprises seeking a comprehensive AI strategy to transform operations and maintain a competitive edge."
            features={[
              "Everything in the Pro Plan",
              "Comprehensive AI strategies for enterprise-wide implementation",
              "Long-term AI scalability planning",
              "Competitor analysis and market positioning with AI",
              "3-hour planning session with senior AI consultants",
              "90 days of ongoing support post-delivery",
              "Premium access to future updates and AI resources"
            ]}
            cta="Contact Sales"
          />
        </div>

        {/* Features and Benefits Section */}
        <div className="mt-20">
          <h2 className="text-4xl font-bold text-center mb-4 text-[#f5f5f7]">
            Features and Benefits
          </h2>
          <p className="text-2xl text-center text-[#f5f5f7]/60 mb-12">
            Compare our plans to find the perfect fit for your business
          </p>
          <div className="overflow-x-auto rounded-lg border border-[#f5f5f7]/10">
            <table className="w-full bg-[#161617]">
              <thead className="bg-[#161617] border-b border-[#f5f5f7]/10">
                <tr>
                  <th className="py-4 px-6 text-left text-xl font-semibold text-[#f5f5f7]">Feature</th>
                  <th className="py-4 px-6 text-center text-xl font-semibold text-[#f5f5f7]">Basic Plan</th>
                  <th className="py-4 px-6 text-center text-xl font-semibold text-[#f5f5f7]">Pro Plan</th>
                  <th className="py-4 px-6 text-center text-xl font-semibold text-[#f5f5f7]">Enterprise Plan</th>
                </tr>
              </thead>
              <tbody>
                {features_and_benefits.map((item, index) => (
                  <FeatureRow key={index} {...item} />
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* CTA Section */}
        <div className="mt-20 border border-[#f5f5f7]/10 rounded-lg p-8 text-center">
          <h2 className="text-3xl font-bold text-[#f5f5f7] mb-4">
            Ready to Transform Your Business with AI?
          </h2>
          <p className="text-xl text-[#f5f5f7]/60 mb-8">
            Get started today and receive your personalized AI implementation roadmap.
          </p>
          <button 
            onClick={() => navigate('/signup')}
            className="bg-[#FF7124] text-[#f5f5f7] font-bold py-3 px-8 rounded-lg hover:bg-[#FF7124]/90 
              transition duration-300 flex items-center justify-center mx-auto"
          >
            Get Started Now
            <FaArrowRight className="ml-2" />
          </button>
        </div>

        <p className="text-center text-[#f5f5f7]/60 mt-12 mb-12 text-lg">
          Prices exclude VAT. Custom solutions available upon request for businesses with unique needs.
        </p>
      </div>
    </section>
  );
};

export default Pricing;
