import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheck, FaEdit, FaCreditCard, FaArrowRight } from 'react-icons/fa';

const Review = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formData = location.state?.formData || {};

  const sections = [
    {
      title: 'Organization Profile',
      fields: [
        { key: 'organizationName', label: 'Organization Name' },
        { key: 'industry', label: 'Industry' },
        { key: 'country', label: 'Country' },
        { key: 'companySize', label: 'Company Size' },
        { key: 'revenue', label: 'Annual Revenue' }
      ]
    },
    {
      title: 'Business Challenges',
      fields: [
        { key: 'operationalChallenges', label: 'Operational Challenges', type: 'checkbox' },
        { key: 'customerChallenges', label: 'Customer Challenges', type: 'checkbox' },
        { key: 'growthBlockers', label: 'Growth Blockers', type: 'checkbox' },
        { key: 'timeSpent', label: 'Time Spent on Repetitive Tasks' },
        { key: 'supportLevel', label: 'Required AI Support Level' }
      ]
    },
    // ... Add similar sections for other steps
  ];

  const renderValue = (value, type) => {
    if (!value) return 'Not specified';
    
    if (type === 'checkbox') {
      const selectedItems = Object.entries(value)
        .filter(([_, selected]) => selected)
        .map(([key]) => key);
      return selectedItems.length ? selectedItems.join(', ') : 'None selected';
    }
    
    return value;
  };

  const handleEdit = () => {
    navigate('/dashboard', { state: { formData } });
  };

  const handleProceedToPricing = () => {
    navigate('/pricing', { state: { formData } });
  };

  return (
    <div className="min-h-screen bg-[#0f0f10] text-[#f5f5f7] p-8">
      <div className="max-w-4xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-bold text-[#f5f5f7]">Review Your Responses</h1>
          <button
            onClick={handleEdit}
            className="flex items-center gap-2 px-4 py-2 text-sm bg-[#161617] 
                     text-[#f5f5f7] border border-[#f5f5f7]/10 rounded-lg 
                     hover:bg-[#1c1c1d] transition-colors duration-200"
          >
            <FaEdit className="h-4 w-4" />
            Edit Responses
          </button>
        </div>

        <div className="space-y-8">
          {sections.map((section) => (
            <div
              key={section.title}
              className="bg-[#161617] border border-[#f5f5f7]/10 rounded-lg p-6"
            >
              <h2 className="text-lg font-semibold mb-4 text-[#f5f5f7]">{section.title}</h2>
              <div className="space-y-4">
                {section.fields.map((field) => (
                  <div key={field.key} className="flex flex-col">
                    <span className="text-sm text-[#f5f5f7]/60">{field.label}</span>
                    <span className="mt-1 text-[#f5f5f7]">
                      {renderValue(formData[field.key], field.type)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 flex justify-end gap-4">
          <button
            onClick={handleEdit}
            className="px-6 py-3 text-sm bg-[#161617] text-[#f5f5f7] 
                     border border-[#f5f5f7]/10 rounded-lg 
                     hover:bg-[#1c1c1d] transition-colors duration-200"
          >
            Go Back
          </button>
          <button
            onClick={handleProceedToPricing}
            className="flex items-center gap-2 px-6 py-3 text-sm bg-[#FF5733] 
                     text-[#f5f5f7] rounded-lg hover:bg-[#FF5733]/90 
                     transition-colors duration-200"
          >
            <FaArrowRight className="h-4 w-4" />
            View Pricing Plans
          </button>
        </div>
      </div>
    </div>
  );
};

export default Review; 