import React from 'react';
import { 
  FaExclamationCircle,
  FaUsers,
  FaChartLine,
  FaClock,
  FaRobot
} from 'react-icons/fa';

const BusinessChallenges = ({ formData, setFormData }) => {
  const operationalChallenges = [
    'Inefficient processes',
    'High costs',
    'Low productivity',
    'Customer dissatisfaction',
    'Other'
  ];

  const customerChallenges = [
    'Low customer retention',
    'High support demands',
    'Slow response times',
    'Other'
  ];

  const growthBlockers = [
    'Lack of data insights',
    'Limited resources',
    'High operational costs',
    'Competitive pressure',
    'Other'
  ];

  const timeSpentOptions = [
    'Minimal (Less than 10%)',
    'Moderate (10%-30%)',
    'Significant (30%-60%)',
    'Excessive (More than 60%)'
  ];

  const supportLevelOptions = [
    'Minimal',
    'Moderate',
    'Significant',
    'Extensive'
  ];

  const handleCheckboxChange = (category, value) => {
    setFormData(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [value]: !prev[category]?.[value]
      }
    }));
  };

  const handleRadioChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const CheckboxGroup = ({ title, icon: Icon, options, category }) => (
    <div>
      <div className="flex items-center gap-2 mb-4">
        <Icon className="h-5 w-5 text-[#f5f5f7]/60" />
        <label className="block text-sm font-medium text-[#f5f5f7]/80">
          {title}
        </label>
      </div>
      <div className="space-y-4 pl-7">
        {options.map((option) => (
          <div key={option} className="flex items-center group">
            <div className="relative flex items-center">
              <input
                type="checkbox"
                id={`${category}-${option}`}
                checked={formData[category]?.[option] || false}
                onChange={() => handleCheckboxChange(category, option)}
                className="peer h-5 w-5 cursor-pointer appearance-none rounded-md border 
                         border-[#f5f5f7]/10 bg-[#161617] checked:border-[#FF5733] 
                         checked:bg-[#FF5733] hover:border-[#FF5733]/50
                         focus:ring-2 focus:ring-[#FF5733]/20 focus:ring-offset-2
                         focus:ring-offset-[#161617] transition-all duration-200"
              />
              <div className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 
                          -translate-y-1/2 text-white opacity-0 peer-checked:opacity-100 
                          transition-opacity">
                <svg className="h-3 w-3" viewBox="0 0 12 12" fill="none">
                  <path
                    d="M10 3L4.5 8.5L2 6"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <label
              htmlFor={`${category}-${option}`}
              className="ml-3 text-sm text-[#f5f5f7]/80 cursor-pointer 
                       group-hover:text-[#f5f5f7] transition-colors duration-200"
            >
              {option}
            </label>
            {option === 'Other' && formData[category]?.['Other'] && (
              <input
                type="text"
                placeholder="Please specify"
                className="ml-3 px-3 py-1.5 text-sm bg-[#161617] border border-[#f5f5f7]/10 rounded
                         text-[#f5f5f7] placeholder-[#f5f5f7]/40
                         focus:ring-1 focus:ring-[#FF5733] focus:border-[#FF5733]"
                value={formData[`${category}Other`] || ''}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  [`${category}Other`]: e.target.value
                }))}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const RadioGroup = ({ title, icon: Icon, options, name }) => (
    <div>
      <div className="flex items-center gap-2 mb-3">
        <Icon className="h-5 w-5 text-[#f5f5f7]/60" />
        <label className="block text-sm font-medium text-[#f5f5f7]/80">
          {title}
        </label>
      </div>
      <div className="space-y-3 pl-7">
        {options.map((option) => (
          <div key={option} className="flex items-center">
            <input
              type="radio"
              id={`${name}-${option}`}
              name={name}
              value={option}
              checked={formData[name] === option}
              onChange={(e) => handleRadioChange(name, e.target.value)}
              className="h-4 w-4 border-[#f5f5f7]/10 bg-[#161617] 
                       text-[#FF5733] focus:ring-[#FF5733] focus:ring-offset-[#161617]"
            />
            <label
              htmlFor={`${name}-${option}`}
              className="ml-3 text-sm text-[#f5f5f7]/80"
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      <CheckboxGroup
        title="What are your current operational challenges?"
        icon={FaExclamationCircle}
        options={operationalChallenges}
        category="operationalChallenges"
      />

      <CheckboxGroup
        title="Which customer-related challenges does your business face?"
        icon={FaUsers}
        options={customerChallenges}
        category="customerChallenges"
      />

      <CheckboxGroup
        title="What are the biggest blockers to your growth?"
        icon={FaChartLine}
        options={growthBlockers}
        category="growthBlockers"
      />

      <RadioGroup
        title="How much time is spent on repetitive tasks in your organization?"
        icon={FaClock}
        options={timeSpentOptions}
        name="timeSpent"
      />

      <RadioGroup
        title="How much support do you require from an AI roadmap?"
        icon={FaRobot}
        options={supportLevelOptions}
        name="supportLevel"
      />
    </div>
  );
};

export default BusinessChallenges; 