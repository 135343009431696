import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { FaExclamationCircle } from 'react-icons/fa';

// Confirmation Modal Component
const SignOutModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      {/* Backdrop */}
      <div className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity" />

      {/* Modal */}
      <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div className="relative transform overflow-hidden rounded-lg bg-[#161617] border border-[#f5f5f7]/10 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#FF5733]/10 sm:mx-0 sm:h-10 sm:w-10">
              <FaExclamationCircle className="h-6 w-6 text-[#FF5733]" />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-lg font-semibold leading-6 text-[#f5f5f7]">
                Sign Out Confirmation
              </h3>
              <div className="mt-2">
                <p className="text-sm text-[#f5f5f7]/60">
                  Are you sure you want to sign out? You will need to sign in again to access your account.
                </p>
              </div>
            </div>
          </div>
          
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
            <button
              type="button"
              onClick={onConfirm}
              className="inline-flex w-full justify-center rounded-lg bg-[#FF5733] px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#FF5733]/90 sm:w-auto"
            >
              Sign Out
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 inline-flex w-full justify-center rounded-lg bg-[#f5f5f7]/5 px-3 py-2 text-sm font-semibold text-[#f5f5f7] shadow-sm ring-1 ring-inset ring-[#f5f5f7]/10 hover:bg-[#f5f5f7]/10 sm:mt-0 sm:w-auto"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Add check for BusinessEvaluation page
  const isBusinessEvaluation = location.pathname === '/business-evaluation';
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // If on BusinessEvaluation page and mobile, don't show navbar
  if (isBusinessEvaluation && window.innerWidth < 768) {
    return null;
  }

  const navigationItems = [
    { name: 'Home', path: '/' },
    { name: 'Use Cases', path: '/use-cases' },
    { name: 'Pricing', path: '/pricing' },
  ];

  const handleSignOutClick = () => {
    setShowSignOutModal(true);
  };

  const handleSignOutConfirm = async () => {
    try {
      await signOut(auth);
      setShowSignOutModal(false);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <>
      <nav className="bg-[#161617] backdrop-blur-md fixed w-full top-0 z-50">
        <div className="max-w-[980px] mx-auto">
          <div className="flex justify-between h-12 items-center px-4">
            {/* Logo */}
            <Link to="/" className="flex-shrink-0">
              <img
                className="h-8 w-auto"
                src="/logo.png"
                alt="Logo"
              />
            </Link>

            {/* Desktop Navigation - Centered */}
            <div className="hidden md:flex flex-1 justify-center">
              <div className="flex space-x-8">
                {navigationItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.path}
                    className={`text-sm ${
                      location.pathname === item.path
                        ? 'text-[#f5f5f7]'
                        : 'text-[#f5f5f7]/80 hover:text-[#f5f5f7]'
                    } transition-colors duration-200`}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            {/* Right side - Auth buttons */}
            <div className="hidden md:flex items-center space-x-4">
              {user ? (
                <div className="flex items-center space-x-4">
                  <Link
                    to="/dashboard"
                    className="text-sm text-[#f5f5f7]/80 hover:text-[#f5f5f7]"
                  >
                    Dashboard
                  </Link>
                  <button
                    onClick={handleSignOutClick}
                    className="text-sm text-[#f5f5f7]/80 hover:text-[#f5f5f7]"
                  >
                    Sign Out
                  </button>
                </div>
              ) : (
                <Link
                  to="/signup"
                  className="text-sm text-[#f5f5f7]/80 hover:text-[#f5f5f7]"
                >
                  Get Started
                </Link>
              )}
            </div>

            {/* Mobile menu button */}
            <div className="flex items-center md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-[#f5f5f7]/80 hover:text-[#f5f5f7]"
              >
                <span className="sr-only">Open main menu</span>
                {!isOpen ? (
                  <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Mobile menu */}
      <div 
        className={`md:hidden fixed top-12 inset-x-0 z-40 bg-[#161617] border-t border-[#f5f5f7]/10 min-h-screen
          transform transition-all duration-500 ease-in-out ${
          isOpen 
            ? 'opacity-100 translate-y-0'
            : 'opacity-0 -translate-y-8 pointer-events-none'
        }`}
      >
        <div className="px-6 sm:px-8 py-6 h-full overflow-y-auto">
          {navigationItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`block text-[28px] sm:text-[32px] leading-tight font-semibold mb-6 
                transition-all duration-300 ease-in-out
                ${location.pathname === item.path
                  ? 'text-[#f5f5f7]'
                  : 'text-[#f5f5f7]/80 hover:text-[#f5f5f7]'
                }`}
              onClick={() => setIsOpen(false)}
            >
              {item.name}
            </Link>
          ))}
          
          {/* Mobile Auth Links */}
          {user ? (
            <>
              <Link
                to="/dashboard"
                className="block text-[28px] sm:text-[32px] leading-tight font-semibold text-[#f5f5f7]/80 hover:text-[#f5f5f7] mb-6
                  transition-all duration-300 ease-in-out"
                onClick={() => setIsOpen(false)}
              >
                Dashboard
              </Link>
              <button
                onClick={handleSignOutClick}
                className="block text-[28px] sm:text-[32px] leading-tight font-semibold text-[#f5f5f7]/80 hover:text-[#f5f5f7]
                  transition-all duration-300 ease-in-out"
              >
                Sign Out
              </button>
            </>
          ) : (
            <Link
              to="/signup"
              className="block text-[28px] sm:text-[32px] leading-tight font-semibold text-[#f5f5f7]/80 hover:text-[#f5f5f7]
                transition-all duration-300 ease-in-out"
              onClick={() => setIsOpen(false)}
            >
              Get Started
            </Link>
          )}
        </div>
      </div>

      {/* Add the SignOutModal */}
      <SignOutModal
        isOpen={showSignOutModal}
        onClose={() => setShowSignOutModal(false)}
        onConfirm={handleSignOutConfirm}
      />
    </>
  );
};

export default Navbar; 