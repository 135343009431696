import React, { useState } from 'react';
import { FaChartBar, FaCogs, FaSearchDollar, FaShieldAlt, FaSyncAlt, FaCheck, FaTimes } from 'react-icons/fa';

const ComparisonTable = () => {
  const [activeTab, setActiveTab] = useState('Customer Data Analysis');

  const tasks = [
    {
      name: 'Customer Data Analysis',
      icon: <FaChartBar />,
      description: "Analyze customer behavior and preferences to drive business decisions.",
      traditional: [
        'High cost due to specialized software and personnel',
        'Several days to complete, delaying decision-making'
      ],
      modiAI: [
        'Lower cost with automated AI-driven analysis',
        'Results available in hours, enabling quick action'
      ]
    },
    {
      name: 'Task Automation Setup',
      icon: <FaCogs />,
      description: "Set up automated processes to increase efficiency and reduce manual work.",
      traditional: [
        'Custom coding requires extensive development time',
        'Weeks of setup and testing before deployment'
      ],
      modiAI: [
        'Pre-built AI recommendations for quick implementation',
        'Set up in days with minimal coding required'
      ]
    },
    {
      name: 'Market Trend Identification',
      icon: <FaSearchDollar />,
      description: "Identify and analyze market trends to stay ahead of the competition.",
      traditional: [
        'Requires specialists for in-depth market analysis',
        'Time-intensive process of data collection and interpretation'
      ],
      modiAI: [
        'Instant insights with AI-driven analysis of market data',
        'Continuous monitoring and real-time trend updates'
      ]
    },
    {
      name: 'Compliance and Security Checks',
      icon: <FaShieldAlt />,
      description: "Ensure compliance with regulations and maintain robust security measures.",
      traditional: [
        'Manual audits require significant human resources',
        'Days of work to thoroughly check all systems'
      ],
      modiAI: [
        'Automated checks powered by AI algorithms',
        'Comprehensive scans completed in just a few hours'
      ]
    },
    {
      name: 'Process Optimization',
      icon: <FaSyncAlt />,
      description: "Optimize business processes to improve efficiency and reduce costs.",
      traditional: [
        'High consulting costs for expert analysis',
        'Takes weeks to identify and implement improvements'
      ],
      modiAI: [
        'AI-powered suggestions based on data analysis',
        'Results and implementation plans available in days'
      ]
    }
  ];

  return (
    <section className="py-24 bg-[#161617] relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <h2 className="text-4xl font-bold text-center mb-16 text-[#f5f5f7]">
          ModiAI vs Traditional Methods
        </h2>

        {/* Tabs - Updated for mobile buttons */}
        <div className="flex overflow-x-auto md:flex-wrap md:flex-row gap-2 pb-2 md:pb-0 
          scrollbar-hide -mx-4 md:mx-0 px-4 md:px-0">
          {tasks.map((task) => (
            <button
              key={task.name}
              className={`group flex items-center px-4 py-2 rounded-lg transition-all duration-300 
                flex-shrink-0 md:flex-shrink md:w-auto
                ${activeTab === task.name
                  ? 'bg-[#FF7124]'
                  : 'bg-[#f5f5f7]/5 hover:bg-[#FF7124]/80'
              }`}
              onClick={() => setActiveTab(task.name)}
            >
              <span className="mr-2 text-xl text-[#f5f5f7]">
                {task.icon}
              </span>
              <span className={`text-sm font-medium whitespace-nowrap ${
                activeTab === task.name
                  ? 'text-[#f5f5f7]'
                  : 'text-[#f5f5f7] group-hover:text-[#f5f5f7]'
              }`}>
                {task.name}
              </span>
            </button>
          ))}
        </div>

        {/* Content - Updated padding for mobile */}
        {tasks.map((task) => (
          task.name === activeTab && (
            <div key={task.name} className="relative mt-8 px-4 md:px-0">
              <p className="text-[#f5f5f7]/60 text-lg mb-12 text-center max-w-3xl mx-auto">
                {task.description}
              </p>
              
              <div className="grid md:grid-cols-2 gap-8">
                {/* Traditional Method */}
                <div className="relative group">
                  {/* Glow effect - updated from red to subtle white */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-[#f5f5f7]/20 to-[#f5f5f7]/10 
                    rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500">
                  </div>
                  
                  <div className="relative p-8 rounded-lg border border-[#f5f5f7]/10 
                    bg-[#161617] backdrop-blur-sm">
                    <h3 className="text-xl font-semibold mb-6 text-[#f5f5f7]/80">
                      Traditional Method
                    </h3>
                    <ul className="space-y-4">
                      {task.traditional.map((point, index) => (
                        <li key={index} className="flex items-start">
                          <FaTimes className="text-[#f5f5f7]/60 mt-1 mr-3 flex-shrink-0" />
                          <span className="text-[#f5f5f7]/60">{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* ModiAI Method */}
                <div className="relative group">
                  {/* Glow effect */}
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-[#FF7124] to-[#FF7124]/60 
                    rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500">
                  </div>
                  
                  <div className="relative p-8 rounded-lg border border-[#f5f5f7]/10 
                    bg-[#161617] backdrop-blur-sm">
                    <h3 className="text-xl font-semibold mb-6 text-[#FF7124]">
                      With ModiAI
                    </h3>
                    <ul className="space-y-4">
                      {task.modiAI.map((point, index) => (
                        <li key={index} className="flex items-start">
                          <FaCheck className="text-[#FF7124] mt-1 mr-3 flex-shrink-0" />
                          <span className="text-[#f5f5f7]/60">{point}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
    </section>
  );
};

export default ComparisonTable;
