import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';
import { 
  Brain,
  Checklist,
  LightBulb
} from 'react-ios-icons';

const FeatureCard = ({ feature }) => {
  const cardRef = useRef(null);
  const isInView = useInView(cardRef);

  return (
    <div ref={cardRef} className="relative group">
      {/* Glow effect container */}
      <div className={`absolute -inset-0.5 bg-gradient-to-r from-[#FF7124] to-[#FF7124]/60 
        rounded-lg blur transition-opacity duration-500
        ${isInView ? 'md:opacity-0 opacity-100' : 'opacity-0'} 
        md:group-hover:opacity-100`}>
      </div>
      
      {/* Card content */}
      <div className={`relative h-full p-8 rounded-lg border border-[#f5f5f7]/10 
        bg-[#161617] backdrop-blur-sm flex flex-col items-center text-center
        transition-all duration-500
        ${isInView ? 'transform translate-y-0' : 'transform translate-y-4'}`}>
        {/* Icon */}
        <div className="mb-6">
          <feature.Icon 
            className="h-16 w-16" 
            color="#FF7124"
            size={64}
          />
        </div>
        <h3 className="text-[#f5f5f7] text-xl font-semibold mb-4">
          {feature.title}
        </h3>
        <p className="text-[#f5f5f7]/60 text-sm">
          {feature.description}
        </p>
      </div>
    </div>
  );
};

const Solutions = () => {
  const features = [
    {
      Icon: Brain,  // For industry-specific solutions
      title: 'Industry-Specific Use Cases',
      description: 'Focus on industry-specific use cases for meaningful impact.'
    },
    {
      Icon: LightBulb,  // For insights
      title: 'Tailored Insights',
      description: 'Tailored insights that align with your unique business objectives.'
    },
    {
      Icon: Checklist,  // For step-by-step guidance
      title: 'Step-by-Step Guidance',
      description: 'Step-by-step guidance designed for non-technical users.'
    }
  ];

  return (
    <section className="py-24 bg-[#161617] relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Title Section */}
        <div className="text-center mb-16 max-w-4xl mx-auto">
          <h2 className="text-4xl md:text-5xl font-bold text-[#f5f5f7] mb-6">
            Personalized AI Solutions for Every Industry
          </h2>
          <p className="text-[#f5f5f7]/60 text-lg">
            We understand that no two businesses are alike. ModiAI provides a personalized AI roadmap, designed to fit the needs of your industry and align with your business goals. Our onboarding process gathers information about your sector, department focus, and operational priorities. Then, we use this input to develop an AI strategy that's specific to your business, offering solutions that drive efficiency, reduce costs, and improve customer experience.
          </p>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-20">
          {features.map((feature) => (
            <FeatureCard key={feature.title} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Solutions; 