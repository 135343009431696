import React from 'react';

const Partners2 = () => {
  const partners = [
    {
      name: 'IBM',
      logo: '/IBM.jpg',
      alt: 'IBM logo',
      hasWhiteBackground: false
    },
    {
      name: 'Simplified',
      logo: '/Simplified.jpeg',
      alt: 'Simplified logo',
      hasWhiteBackground: false
    },
    {
      name: 'BlackCube',
      logo: '/BlackCube.jpeg',
      alt: 'BlackCube logo',
      hasWhiteBackground: false
    },
    {
      name: 'Partner 4',
      logo: '/logo-modified.png',
      alt: 'Partner 4 logo',
      hasWhiteBackground: true
    }
  ];

  return (
    <section className="py-12 bg-[#161617]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-xl text-[#f5f5f7]/60 mb-8">
          Trusted by leading companies worldwide
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 items-center justify-items-center">
          {partners.map((partner) => (
            <div 
              key={partner.name}
              className="flex items-center justify-center w-full h-20"
            >
              <div className={`flex items-center justify-center h-14 ${
                partner.hasWhiteBackground ? 'bg-white' : ''
              } opacity-60 hover:opacity-100 transition-opacity duration-300`}>
                <img
                  src={partner.logo}
                  alt={partner.alt}
                  className="h-full w-auto"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners2; 