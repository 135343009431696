import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCreditCard, FaLock } from 'react-icons/fa';

const Billing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { formData, planDetails } = location.state || {};
  const paypalButtonsRef = useRef(null);

  useEffect(() => {
    if (!window.paypal) {
      console.error('PayPal SDK not loaded');
      return;
    }

    // Clear any existing buttons
    if (paypalButtonsRef.current) {
      paypalButtonsRef.current.innerHTML = '';
    }

    try {
      window.paypal.Buttons({
        // Style the buttons
        style: {
          layout: 'vertical',   // vertical | horizontal
          color: 'blue',       // gold | blue | silver | black
          shape: 'rect',       // rect | pill
          label: 'paypal'      // paypal | checkout | buynow | pay
        },

        // Create order
        createOrder: function(data, actions) {
          return actions.order.create({
            purchase_units: [{
              description: `ModiAI - ${planDetails?.title}`,
              amount: {
                value: planDetails?.price.replace(/[^0-9.]/g, ''),
                currency_code: 'USD',
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: planDetails?.price.replace(/[^0-9.]/g, '')
                  }
                }
              },
              items: [{
                name: planDetails?.title,
                description: 'AI Implementation Package',
                unit_amount: {
                  currency_code: 'USD',
                  value: planDetails?.price.replace(/[^0-9.]/g, '')
                },
                quantity: '1'
              }]
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING'
            }
          });
        },

        // Finalize the transaction
        onApprove: async function(data, actions) {
          try {
            const order = await actions.order.capture();
            console.log('Capture result', order);
            
            // Show success message
            navigate('/success', { 
              state: {
                orderId: order.id,
                formData,
                planDetails,
                paymentDetails: order
              }
            });
          } catch (err) {
            console.error('Capture error', err);
            // Handle errors here
          }
        },

        // Handle errors
        onError: function(err) {
          console.error('PayPal error', err);
          // Handle errors here
        }
      }).render(paypalButtonsRef.current);
    } catch (err) {
      console.error('PayPal button render error:', err);
    }
  }, [navigate, formData, planDetails]);

  return (
    <div className="min-h-screen bg-[#0f0f10] text-white p-8">
      <div className="max-w-3xl mx-auto text-white">
        <div className="mb-8">
          <h1 className="text-2xl font-bold mb-2 text-white">Complete Your Purchase</h1>
          <p className="text-white/60">
            Choose your preferred payment method to continue
          </p>
        </div>

        <div className="bg-[#161617] border border-white/10 rounded-lg p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl font-semibold text-white">{planDetails?.title}</h2>
            <span className="text-2xl font-bold text-white">{planDetails?.price}</span>
          </div>
          
          <div className="space-y-4 mb-6">
            <div className="flex items-center gap-2">
              <FaLock className="text-green-500" />
              <span className="text-sm text-white">
                Secure, encrypted payment
              </span>
            </div>
            <div className="text-sm text-white">
              {planDetails?.features?.map((feature, index) => (
                <div key={index} className="flex items-start mb-2 text-white">
                  <span className="mr-2 text-white">•</span>
                  <span className="text-white">{feature}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="space-y-6">
            <div id="paypal-button-container" className="min-h-[40px]" />
            
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-white/10" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-[#161617] text-white/60">
                  or pay with card
                </span>
              </div>
            </div>

            <button
              onClick={() => {/* Handle credit card payment */}}
              className="w-full flex items-center justify-center gap-2 px-6 py-3 
                       bg-white text-[#0f0f10] rounded-lg font-medium
                       hover:bg-white/90 transition-colors duration-200"
            >
              <FaCreditCard />
              Pay with Card
            </button>
          </div>
        </div>

        <div className="text-center text-sm text-white/60">
          By completing this purchase, you agree to our{' '}
          <a href="/terms" className="text-[#FF5733] hover:underline">
            Terms of Service
          </a>
          {' '}and{' '}
          <a href="/privacy" className="text-[#FF5733] hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default Billing;
