import React from 'react';
import ReactLoading from 'react-loading';

const LoadingPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#161617]">
      <ReactLoading type="spinningBubbles" color="#FF7124" height={100} width={100} />
      <p className="mt-4 text-xl font-semibold text-[#f5f5f7]">Loading Modi AI...</p>
    </div>
  );
};

export default LoadingPage;
