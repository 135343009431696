import { useState, useEffect } from 'react';

export const useInView = (ref, options = {}) => {
  const [isInView, setIsInView] = useState(false);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsInView(true);
        setHasBeenViewed(true);
      } else if (hasBeenViewed) {
        setIsInView(false);
      }
    }, { threshold: 1, ...options });

    observer.observe(ref.current);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, hasBeenViewed, options]);

  return isInView;
}; 